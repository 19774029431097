import { requestNew } from "../../../util/request";
import { getURL } from "../../../util/util";
import types from "../../types/browser_notifications.types";

/**
 * @author Gulam Hussain, 09 March 2020
 * @description Get list of browser notifications
 */
export function getNotifications(params: { nextCursor: null | number }) {
    return async function(dispatch) {
        if (params.nextCursor === null) {
            dispatch({ type: types.GET_BROWSER_NOTIFICATIONS, payload: { data: [], nextCursor: params.nextCursor } });
        }
        const res = await requestNew({
            method: "GET",
            url: getURL("/api/v1/notifications", params),
        });
        dispatch({ type: types.GET_BROWSER_NOTIFICATIONS, payload: { data: res.data, nextCursor: params.nextCursor } });
        return res;
    };
}

/**
 * @author Gulam Hussain, 09 March 2020
 * @description Get list of browser notifications
 */
export function getNotificationsCount() {
    return async function(dispatch) {
        const res = await requestNew({
            method: "GET",
            url: "/api/v1/notifications/unreadCount",
        });
        dispatch({ type: types.GET_UNREAD_BROWSER_NOTIFICATION_COUNT, payload: { data: res.data } });
        return res;
    };
}

/**
 * @author Gulam Hussain, 09 March 2020
 */
export function addNewNotification(payload) {
    return {
        type: types.ADD_NEW_BROWSER_NOTIFICATION,
        data: { isRead: 0, ...payload },
    };
}

/**
 * @author Gulam Hussain, 09 March 2020
 */
export function markAsRead(count) {
    return {
        type: types.MARK_AS_READ_BROWSER_NOTIFICATION,
        payload: { count },
    };
}

export function updateReadCount(data) {
    return async function(dispatch) {
        const url = `/api/v1/notifications`;
        const res = await requestNew({ method: "PUT", url, data });
        dispatch(markAsRead(data.data.length));
        return res;
    };
}
