import { combineReducers } from "redux";
import { aoc } from "./aoc/index.reducer";
import {
    candidateEventsDataAfterCompletion,
    candidateEventsDataBeforeCompletion,
    candidatesList,
    candidatesTimelineInfo,
    externalUserLink,
} from "./candidate/index.reducer";
import { allDepartments } from "./department/index.reducer";
import { allDocumentTypes } from "./document/index.reducer";
import { users, employeeTypes } from "./employee/index.reducer";
import { assignedFeaturesForTraining, features } from "./feature_training.reducer/index.reducer";
import { jobProfilesByDepartment } from "./job_profiles/index.reducer";
import { allLevels } from "./level/index.reducer";
import candidateTemplates from "./template/template.reducer";
import {
    candidateEvents,
    departmentFeatureMapping,
    candidateEventsDepartmentMapping,
    departmentWiseHandlers,
} from "./admin_activity/admin_activity.reducer";
import onboarding_tasks from "./onboarding_tasks/index.reducer";
import notifications from "../reducers/notification/notification.reducer";
import browserNotifications from "./browser_notifications/index.reducers";
import { blackListedCandidates } from "./black_list_candidates/black_listed_candidates.reducer";
export default combineReducers({
    allDepartments,
    allDocumentTypes,
    jobProfilesByDepartment,
    users,
    candidatesList,
    candidatesTimelineInfo,
    aoc,
    candidateEventsDataBeforeCompletion,
    allLevels,
    candidateEventsDataAfterCompletion,
    assignedFeaturesForTraining,
    features,
    externalUserLink,
    employeeTypes,
    candidateTemplates,
    candidateEvents,
    departmentFeatureMapping,
    candidateEventsDepartmentMapping,
    departmentWiseHandlers,
    onboarding_tasks,
    notifications,
    browserNotifications,
    blackListedCandidates,
});
