import React, { Component } from "react";
import { Drawer, Box } from "@mui/material";
import classes from "./notification.module.scss";
import NotificationSidebarHeader from "./components/sidebar_header";
import { connect } from "react-redux";
import {
    getNotifications,
    updateReadCount,
} from "../../../../store/actions/browser_notifications/browser_notifications.actions";
import LoadingUI from "../../../loader/loader";
import NoDataFoundUI from "../../../no_data_ui/index";
import ErrorUI from "../../../error_block/error_block";
import {
    IBrowserNotificationSidebarProps,
    IBrowserNotificationSidebarState,
    IBrowserNotificationPayload,
} from "../../types";
import NotificationCard from "./components/notification_card";
import { AxiosResponse } from "axios";
import ScrollLoader from "../../../scroll_loader/scroll_loader";
/**
 * @author Gulam Hussain 09 March 2020
 * @description Browser notification sidebar
 */
class BrowserNotificationSidebar extends Component<IBrowserNotificationSidebarProps, IBrowserNotificationSidebarState> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isError: false,
            formData: { nextCursor: null },
        };
    }

    componentDidMount() {
        this.getNotifications();
    }

    getNotifications = (formData = this.state.formData) => {
        this.setState({ isLoading: true, isError: false });
        this.props
            .getNotifications(formData)
            .then((res) => {
                this.updateUnReadCount(res);
                this.setState({ isLoading: false, formData: { ...formData, nextCursor: res.metaData.nextCursor } });
            })
            .catch((err) => {
                this.setState({ isLoading: false, isError: true });
            });
    };

    updateUnReadCount = (response: AxiosResponse) => {
        const data: IBrowserNotificationPayload[] = response.data;
        if (data.length === 0) {
            return;
        }
        const unreadIds = data
            .filter((notification) => notification.isRead === 0)
            .map((notification) => notification.id);
        if (unreadIds.length === 0) {
            return;
        }
        this.props.updateReadCount({ data: unreadIds });
    };

    getUI = () => {
        const { isLoading, isError } = this.state;
        const { list } = this.props;
        const ui: React.ReactElement[] = [];
        const notifications = list.map((notification) => (
            <NotificationCard notification={notification} key={notification.id} />
        ));
        if (notifications.length) {
            ui.push(
                <Box py={2} px={1} key="notification-container">
                    {notifications}
                </Box>
            );
        }
        if (isLoading) {
            ui.push(<LoadingUI key="loader" show={true} message="Getting notifications..." />);
        }
        if (isError) {
            ui.push(
                <ErrorUI show={true} key="error" message="Please retry" retryCallBack={() => this.getNotifications()} />
            );
        }

        if (!isLoading && !isError && list.length === 0) {
            ui.push(<NoDataFoundUI show={true} key="no-data" message="No notifications" />);
        }
        return ui;
    };
    render() {
        const { formData, isLoading } = this.state;
        return (
            <Drawer
                classes={{ paperAnchorRight: classes.drawerRoot }} // make drawer full screen for mobile devices
                anchor="right"
                open={true}
                onClose={this.props.onClose}>
                <NotificationSidebarHeader onClose={this.props.onClose} />
                <Box pb={3}>{this.getUI()}</Box>

                <ScrollLoader
                    hasMoreData={formData.nextCursor ? true : false}
                    isLoading={isLoading}
                    onLoad={() => this.getNotifications()}
                />
            </Drawer>
        );
    }
}

function map({ browserNotifications }) {
    const { list } = browserNotifications;
    return { list };
}
export default connect(map, { getNotifications, updateReadCount })(BrowserNotificationSidebar);
