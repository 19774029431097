import types from "../../types/browser_notifications.types";

export function unreadCount(state = 0, action) {
    switch (action.type) {
        case types.GET_UNREAD_BROWSER_NOTIFICATION_COUNT: {
            const { data } = action.payload;
            return parseInt(data.count, 10);
        }
        case types.ADD_NEW_BROWSER_NOTIFICATION:
            return state + 1;
        case types.MARK_AS_READ_BROWSER_NOTIFICATION: {
            const count = state - action.payload.count;
            return count < 0 ? 0 : count;
        }
        default:
            return state;
    }
}
