import axios from "axios";

const isProd = process.env.NODE_ENV === "production";
const OLD_API = isProd
    ? process.env.REACT_APP_OLD_API_PROD
    : process.env.REACT_APP_OLD_API_LOCAL;

interface IResponseData {
    empID: string;
    accountType: string;
    phoneNumber: string;
    aoc: string;
    accessToken: string;
    level: string;
    fullName: string;
    features: [];
    tokenExpiry?: number;
    refreshToken: string;
}

class User {
    private username: string = "";
    private password: string = "";

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }

    public onSuccess(data: IResponseData) {
        const {
            empID,
            accessToken,
            accountType,
            phoneNumber,
            aoc,
            level,
            fullName,
            features,
            tokenExpiry = 1800,
            refreshToken,
        } = data;
        if (localStorage) {
            localStorage.setItem("empID", empID);
            localStorage.setItem("accountType", accountType);
            localStorage.setItem("phoneNumber", phoneNumber);
            localStorage.setItem("aoc", aoc);
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("level", level);
            localStorage.setItem("fullName", fullName);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem(
                "tokenExpiry",
                (Date.now() + tokenExpiry * 1000).toString(),
            );
            if (features) {
                const featureArray = JSON.stringify(features);
                localStorage.setItem("featureArray", featureArray);
            }
        }
    }

    public login() {
        return new Promise((resolve, reject) => {
            axios({
                url: `${OLD_API}/sign-in`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "appID": 12,
                    "version": "1.1.0",
                },


                method: "POST",
                data: `userName=${this.username}&password=${this.password}`,
            })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.onSuccess(response.data.data);
                        resolve(response.data.message);
                    } else {
                        reject(response.data.message || "Server error");
                    }
                })
                .catch((err) => {
                    reject("Login failed");
                });
        });
    }

    public logout() {
        if (localStorage) {
            localStorage.clear();
            window.location.href = "/login";
        }
    }

    public retryLogin() {
        return new Promise((resolve, reject) => {
            axios({
                url: `${OLD_API}/sign-in/retry`,
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    "appID": "12",
                    "version": "1.1.0",
                    "authorization": localStorage.getItem("refreshToken"),
                    "empID": localStorage.getItem("empID"),
                },
                method: "get",
            })
                .then((res) => {
                    // on successfull response of new token, update the token in local storage
                    if (res.data.status === "success") {
                        localStorage.setItem("accessToken", res.data.data.accessToken );
                        resolve(res.data.data.accessToken);
                    } else {
                        reject();
                    }
                })
                .catch((err) => {
                    reject();
                });
        });
    }
}

export default function(username: string, password: string) {
    return new User(username, password);
}
