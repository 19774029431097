import { AppBar, IconButton, Link, Toolbar, Typography, Box } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import React, { useState } from "react";
import SwipeableTemporaryDrawer from "../sidebar/sidebar";
import classes from "./header.module.scss";
import { makeStyles } from "@mui/styles";
import UserProfile from "./components/user_profile";
import BrowserNotifications from "../browser_notifications";
import { useTheme } from "@mui/material/styles";
/**
 * @author Gulam Hussain,
 * This is Header component of the app.
 * Please don't write any toofani logic here, for example if you want to add a search functionality then make
 * a new component in components folder and implement functionality there, and then import that component and include
 * it here.
 * @constructor
 */

const useStyles = makeStyles(() => ({
    root: {
        "& > *": {
            margin: useTheme().spacing(0.5),
        },
    },
}));

const Header = () => {
    const iconSpacingClasses = useStyles();
    const [drawerOpen, toggleDrawer] = useState(false);
    // toggle sidebar by clicking on sandwhich button
    function handleToggleDrawer() {
        toggleDrawer(!drawerOpen);
    }
    return (
        <div className={classes.grow}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        edge="start"
                        classes={{
                            root: classes.menuButton,
                        }}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleToggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        classes={{
                            root: classes.title,
                        }}
                        variant="h6"
                        noWrap>
                        <Link color="inherit" href="#">
                            Mera Gaon Onboarding
                        </Link>
                    </Typography>
                    <div className={classes.search}></div>
                    <Box display="flex" alignItems="center" className={iconSpacingClasses.root} gap="12px">
                        <UserProfile />
                        <BrowserNotifications />
                    </Box>
                </Toolbar>
            </AppBar>
            <SwipeableTemporaryDrawer drawerOpen={drawerOpen} handleToggleDrawer={handleToggleDrawer} />
        </div>
    );
};

export default Header;
