import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { removeSnackbar } from "../../store/actions/notification/notification.actions";
import { IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
let displayed: any = [];

const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((store: any) => store.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach((notification: any) => {
            const { message, options, dismissed = false } = notification;
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(options.key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(options.key)) {
                return;
            }
            const key = options.key;
            options.action = () => (
                <IconButton
                    color="inherit"
                    onClick={() => {
                        removeDisplayed(key);
                        closeSnackbar(key);
                    }}>
                    <CloseRounded />
                </IconButton>
            );
            const notif = {
                key,
                ...options,
                // onClose: (event, reason, myKey) => {
                //     dispatch(removeSnackbar(myKey));
                //     removeDisplayed(myKey);
                // },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            };
            // display snackbar using notistack
            enqueueSnackbar(message, notif);

            // keep track of snackbars that we've displayed
            storeDisplayed(options.key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
