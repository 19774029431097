import adminActivityTypes, { IFetchCandidateEvent, IFetchDepartmentFeatureMapping, IFetchCandidateEventDepartmentMapping, IFetchHandlerMaster } from "../../types/admin_activity.types";

export const candidateEvents = (state: IFetchCandidateEvent[] = [], action: any): IFetchCandidateEvent[] => {
    switch(action.type) {
        case adminActivityTypes.FETCH_CANDIDATE_EVENT:
            return action.payload;
        case adminActivityTypes.CREATE_CANDIDATE_EVENT: {
            const newState = [...state];
            newState.push({
                id: action.payload.candidateEventId,
                eventId: action.payload.candidateEventId,
                ...action.data
            });
            return newState;
        }
        default:
            return state;
    }
};

export const departmentFeatureMapping = (state: IFetchDepartmentFeatureMapping[] = [], action: any): IFetchDepartmentFeatureMapping[] => {
    switch(action.type) {
        case adminActivityTypes.FETCH_DEPARTMENT_FEATURE_MAPPING:
            return action.payload;
        case adminActivityTypes.CREATE_DEPARTMENT_FEATURE_MAPPING: {
            const newState = [...state];
            newState.push({
                id: action.payload.mappingId,
                mappingId: action.payload.mappingId,
                ...action.data
            });
            return newState;
        }
        default:
            return state;
    }
};

export const candidateEventsDepartmentMapping = (state: IFetchCandidateEventDepartmentMapping[] = [], action: any): IFetchCandidateEventDepartmentMapping[] => {
    switch(action.type) {
        case adminActivityTypes.FETCH_CANDIDATE_EVENTS_DEPARTMENT_MAPPING:
            return action.payload;
        case adminActivityTypes.CREATE_CANDIDATE_EVENTS_DEPARTMENT_MAPPING: {
            const newState = [...state];
            newState.push({
                id: action.payload.mappingId,
                mappingId: action.payload.mappingId,
                ...action.data
            });
            return newState;
        }
        default:
            return state;
    }
};

export const departmentWiseHandlers = (state: IFetchHandlerMaster[] = [], action: any): IFetchHandlerMaster[] => {
    switch(action.type) {
        case adminActivityTypes.FETCH_DEPARTMENT_WISE_HANDLERS:
            return action.payload;
        case adminActivityTypes.CREATE_DEPARTMENT_WISE_HANDLERS: {
            const newState = [...state];
            newState.push({
                id: action.payload.handlerId,
                ...action.data
            });
            return newState;
        }
        default:
            return state;
    }
};
