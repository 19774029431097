import {
    ICandidateCompletedEventData,
    ICandidateFullInfo,
} from "../../pages/candidates/components/detailed_panel/detailed_panel.type";

const candidateActionTypes: ICandidateActionsTypes = {
    CREATE_NEW_CANDIDATE_PROFILE: "CREATE_NEW_CANDIDATE_PROFILE",
    FETCH_ALL_CANDIDATES_LIST: "FETCH_ALL_CANDIDATES_LIST",
    FETCH_CANDIDATE_TIMELINE_INFO: "FETCH_CANDIDATE_TIMELINE_INFO",
    FINISH_CANDIDATE_EVENT: "FINISH_CANDIDATE_EVENT",
    FETCH_CANDIDATE_EVENT_DATA_BEFORE_COMPLETION: "FETCH_CANDIDATE_EVENT_DATA_BEFORE_COMPLETION",
    FETCH_CANDIDATE_EVENT_DATA_AFTER_COMPLETION: "FETCH_CANDIDATE_EVENT_DATA_AFTER_COMPLETION",
    RESEND_CANDIDATE_EVENT_EMAIL: "RESEND_CANDIDATE_EVENT_EMAIL",
    REJECT_CANDIDATE_APPLICATION: "REJECT_CANDIDATE_APPLICATION",
    UPLOAD_EXTRA_DOCUMENTS: "UPLOAD_EXTRA_DOCUMENTS",
    UPDATE_COMPLETED_EVENT_COUNT: "UPDATE_COMPLETED_EVENT_COUNT",
    UPDATE_BASE_LOCATION_IN_EVENT: "UPDATE_BASE_LOCATION_IN_EVENT",
    UPDATE_BASE_LOCATION_IN_CANDIDATE_LIST: "UPDATE_BASE_LOCATION_IN_CANDIDATE_LIST",
    UPDATE_BASE_LOCATION_IN_CANDIDATE_DETAIL_INFO: "UPDATE_BASE_LOCATION_IN_CANDIDATE_DETAIL_INFO",
    UPDATE_OPS_FEATURE_TRAINING_STATUS: "UPDATE_OPS_FEATURE_TRAINING_STATUS",
    CANDIDATE_LAST_KNOWN_LOCATION: "CANDIDATE_LAST_KNOWN_LOCATION",
    UPDATE_OPS_FEATURE_ASSIGNMENT_STATUS: "UPDATE_OPS_FEATURE_ASSIGNMENT_STATUS",
    UPDATE_HANDLER: "UPDATE_HANDLER",
    UPDATE_CANDIDATE_DETAILED_INFO: "UPDATE_CANDIDATE_DETAILED_INFO",
    UPDATE_CANDIDATE_ROW_INFO: "UPDATE_CANDIDATE_ROW_INFO",
    UPDATE_CANDIDATE_DOJ_IN_DETAILED_INFO: "UPDATE_CANDIDATE_DOJ_IN_DETAILED_INFO",
    UPDATE_CANDIDATE_DOJ_IN_ROW_INFO: "UPDATE_CANDIDATE_DOJ_IN_ROW_INFO",
    GET_CANDIDATE_BASE_LOCATION_HISTORY: "GET_CANDIDATE_BASE_LOCATION_HISTORY",
    GET_EXTERNAL_USER_LINK: "GET_EXTERNAL_USER_LINK",
    UPDATE_EXTERNAL_USER_LINK: "UPDATE_EXTERNAL_USER_LINK",
    ADD_CANDIDATE_TO_THE_BLACK_LIST: "ADD_CANDIDATE_TO_THE_BLACK_LIST",
};

interface ICandidateActionsTypes {
    CREATE_NEW_CANDIDATE_PROFILE: string;
    FETCH_ALL_CANDIDATES_LIST: string;
    FETCH_CANDIDATE_TIMELINE_INFO: string;
    FINISH_CANDIDATE_EVENT: string;
    FETCH_CANDIDATE_EVENT_DATA_BEFORE_COMPLETION: string;
    FETCH_CANDIDATE_EVENT_DATA_AFTER_COMPLETION: string;
    RESEND_CANDIDATE_EVENT_EMAIL: string;
    REJECT_CANDIDATE_APPLICATION: string;
    UPLOAD_EXTRA_DOCUMENTS: string;
    UPDATE_COMPLETED_EVENT_COUNT: string;
    UPDATE_BASE_LOCATION_IN_EVENT: string;
    UPDATE_BASE_LOCATION_IN_CANDIDATE_LIST: string;
    UPDATE_BASE_LOCATION_IN_CANDIDATE_DETAIL_INFO: string;
    UPDATE_OPS_FEATURE_TRAINING_STATUS: string;
    CANDIDATE_LAST_KNOWN_LOCATION: string;
    UPDATE_OPS_FEATURE_ASSIGNMENT_STATUS: string;
    UPDATE_HANDLER: string;
    UPDATE_CANDIDATE_DETAILED_INFO: string;
    UPDATE_CANDIDATE_ROW_INFO: string;
    UPDATE_CANDIDATE_DOJ_IN_DETAILED_INFO: string;
    UPDATE_CANDIDATE_DOJ_IN_ROW_INFO: string;
    GET_CANDIDATE_BASE_LOCATION_HISTORY: string;
    GET_EXTERNAL_USER_LINK: string;
    UPDATE_EXTERNAL_USER_LINK: string;
    ADD_CANDIDATE_TO_THE_BLACK_LIST: string;
}

export default candidateActionTypes;

export interface ICandidatesTimeLineInfoReduxState {
    [key: string]: ICandidateFullInfo;
}

export interface ICandidateEventsBeforeCompletionReduxState {
    [key: string]: {
        [key: string]: any;
    };
}

export interface ICandidateEventsAfterCompletionReduxState {
    [key: string]: {
        [key: string]: ICandidateCompletedEventData;
    };
}

export interface IExternalUserLink {
    refId: number;
    refTable: number;
}
