import React, { Component } from "react";
import SocketHandler from "./components/socket";
import { IconButton, Badge } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import Sidebar from "./components/notifications/notification_sidebar";
import { connect } from "react-redux";
import {
    getNotificationsCount,
    addNewNotification,
} from "../../store/actions/browser_notifications/browser_notifications.actions";

/**
 * @author Gulam Hussain
 * @description Handle Notification Permissions, Incoming notification socket. and Display Browser Notifications
 */
class BrowserNotifications extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            isSidebarOpen: false,
            isCountLoading: false,
        };
    }
    componentDidMount() {
        this.props.getNotificationsCount();
    }
    toggleSidebar = () => {
        this.setState((state) => ({ isSidebarOpen: !state.isSidebarOpen }));
    };

    render(): React.ReactFragment {
        const { isSidebarOpen } = this.state;
        return (
            <React.Fragment>
                <IconButton onClick={this.toggleSidebar} color="inherit">
                    <Badge overlap="circular" max={999} badgeContent={this.props.unreadCount} color="secondary">
                        <Notifications />
                    </Badge>
                </IconButton>
                <SocketHandler addNewNotification={this.props.addNewNotification} />
                {isSidebarOpen ? <Sidebar onClose={this.toggleSidebar} /> : null}
            </React.Fragment>
        );
    }
}

function map({ browserNotifications }) {
    const { unreadCount } = browserNotifications;
    return { unreadCount };
}
export default connect(map, { getNotificationsCount, addNewNotification })(BrowserNotifications);
