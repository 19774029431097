import jobActionTypes from "../../types/job_profile.types";

export const jobProfilesByDepartment = (state: IJobProfilesByDepartment[] = [], action: any): IJobProfilesByDepartment[] => {
    if (action.type === jobActionTypes.FETCH_JOB_PROFILE_BY_DEPARTMENT_ID) {
        return action.payload;
    } else {
        return state;
    }
};

export interface IJobProfilesByDepartment {
    id: number;
    departmentId: number;
    name: string;
}
