import { Component } from "react";
import socketIOClient from "socket.io-client";
import { getNotificationPermission } from "../../../util/util";
import { ISocketPayload } from "../types";
const LOGGEDIN_EMPID = localStorage.getItem("empID");
const isProd = process.env.NODE_ENV === "production";
const NEW_API: string | undefined = isProd ? process.env.REACT_APP_NEW_API_PROD : process.env.REACT_APP_NEW_API_LOCAL;

/**
 * @author Gulam Hussain
 * @description Handle Notification Permissions, Incoming notification socket. and Display Browser Notifications
 */
class SocketHandler extends Component<any, any> {
    socket: socketIOClient.Socket;

    componentDidMount() {
        getNotificationPermission().then(() => {
            this.establishSocket();
        });
    }

    componentWillUnmount() {
        this.socket.close();
    }

    establishSocket = () => {
        this.socket = socketIOClient(NEW_API);
        // 12 is Onboarding AppId
        this.socket.on("general-12", this.handleIncommingMessage);
    };

    handleIncommingMessage = (payload: ISocketPayload) => {
        // if (document.hidden) {
        //     return;
        // }
        const { receivers } = payload;
        const shouldShow = receivers.some((empId) => empId === LOGGEDIN_EMPID);

        if (!shouldShow) {
            return;
        }

        this.showNotification(payload);
    };

    showNotification = (data: ISocketPayload) => {
        const { title, icon, content, actions } = data;
        const notification = new Notification(title, {
            icon,
            body: content,
        });
        notification.onclick = function() {
            window.open(`${JSON.parse(actions)[0].link}`);
        };
        this.props.addNewNotification(data);
    };

    render() {
        return null;
    }
}
export default SocketHandler;
