import { Button, Typography } from "@mui/material";
import React from "react";
import notFoundSvg from "../../static/assets/images/not_found.svg";
import classes from "./not_found.module.scss";

/**
 * @author Shubam Gupta
 * @constructor
 */
export default function NotFound() {
    function redirect() {
        window.location.href = "/";
    }

    return (
        <div className={classes.wrapper}>
            <img src={notFoundSvg} className={classes.imgStyle} alt="Not Found" />
            {/* <span className={classes.mainText}>
                404
            </span> */}
            <Typography align="center" variant="h2">
                404, Not Found
            </Typography>
            <Typography align="center" variant="h5">
                sorry the page you're looking for is not on our server
            </Typography>
            <Button color="primary" variant="outlined" classes={{ outlined: classes.btnHome }} onClick={redirect}>
                Return to Home
            </Button>
        </div>
    );
}
