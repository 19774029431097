import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import React from "react";
import { Link } from "react-router-dom";
import { IFeatureList } from "../../util/features/feature_listing";
import getSidebarMenuFeature from "../../util/features/get_sidebar_menu";
import classes from "./sidebar.module.scss";

// get the features that needs to be listed in sidebar menu
const sidebarMenuFeatures: IFeatureList = getSidebarMenuFeature();

/**
 * @author Gulam Hussain
 * @param props
 * @constructor
 * This component is imported in Header component of the app.
 * There is not much going on here.
 * You probably never need to change this component
 */
export default function SwipeableTemporaryDrawer(props) {
    const mainFeatures: string[] = Object.keys(sidebarMenuFeatures);
    return (
        <div>
            <SwipeableDrawer
                open={props.drawerOpen}
                onClose={props.handleToggleDrawer}
                onOpen={props.handleToggleDrawer}
                classes={{ root: classes.drawerRoot }}>
                <div className={classes.sidebar} role="presentation">
                    <TreeView
                        className={classes.sidebarTreeView}
                        defaultCollapseIcon={<KeyboardArrowUp />}
                        defaultExpandIcon={<ExpandMoreIcon />}>
                        {mainFeatures.map((mainFeatureText, index) => {
                            const subFeaturesObject = sidebarMenuFeatures[mainFeatureText];
                            const subFeaturesList = Object.keys(subFeaturesObject);
                            return (
                                <TreeItem
                                    key={index}
                                    nodeId={`mainFeature-${index}`}
                                    label={mainFeatureText}
                                    classes={{
                                        content: classes.treeItemContent,
                                        expanded: classes.treeItemWrapper_expanded,
                                        group: classes.treeGroup,
                                        iconContainer: classes.iconContainer,
                                        label: classes.treeLabel,
                                        root: classes.treeItemWrapper,
                                    }}>
                                    {subFeaturesList.map((subFeatureName, subFeatureIndex) => {
                                        const { name, url, isProtected, icon } = subFeaturesObject[subFeatureName];
                                        return (
                                            <StyledTreeItem
                                                key={subFeatureIndex}
                                                labelIcon={icon}
                                                labelText={name}
                                                nodeId={`subFeature-${url}`}
                                                isProtected={isProtected}
                                                url={url}
                                                toggleSidebar={props.handleToggleDrawer}
                                            />
                                        );
                                    })}
                                </TreeItem>
                            );
                        })}
                    </TreeView>
                </div>
            </SwipeableDrawer>
        </div>
    );
}

/**
 * isReact value
 */
function StyledTreeItem(props) {
    const { labelIcon: LabelIcon, nodeId, url, labelText } = props;
    return (
        <TreeItem
            nodeId={nodeId}
            onClick={props.toggleSidebar}
            label={
                <Link to={url} className={classes.treeItem}>
                    <LabelIcon fontSize="small" className={classes.itemIcon} />
                    <div className={classes.itemText}>{labelText}</div>
                </Link>
            }
        />
    );
}
