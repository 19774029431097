import { Box, Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { Alert } from "@mui/lab";
import React, { ReactElement } from "react";

/**
 * @author Gulam Hussain
 * @param props {IErrorBlock}
 * @constructor
 * This Component is use to show Error message.
 * It has retry callback props which takes a function which will retry to do whatever failed in the first place
 */
function ErrorBlock(props: IErrorBlock): ReactElement | null {
    if (!props.show) {
        return null;
    }
    return (
        <Box display="flex" justifyContent="center">
            <Alert
                severity="error"
                action={
                    props.retryCallBack ? (
                        <Button endIcon={props.retryIcon || <Refresh />} onClick={props.retryCallBack}></Button>
                    ) : null
                }>
                {props.message || "An Error occurred, please try again"}
            </Alert>
        </Box>
    );
}
export default ErrorBlock;

interface IErrorBlock {
    show: boolean;
    message: string;
    retryCallBack?: any;
    retryIcon?: JSX.Element;
}
