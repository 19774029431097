import React from "react";
import { Box, Card, Link, CardContent, Avatar, Typography } from "@mui/material";
import { IBrowserNotificationPayload } from "../../../types";
import { FiberNewOutlined } from "@mui/icons-material";
/**
 * @author Gulam Hussain, 09 March 2021
 * @param props {notification: IBrowserNotificationPayload}
 * @description Browser Notification Card
 */
function NotificationCard(props: { notification: IBrowserNotificationPayload }) {
    const { iconUrl, title, content, actions, timestamp, isRead } = props.notification;
    return (
        <Box mb={2} borderRadius="5" position="relative">
            <Link color="inherit" target="_blank" href={JSON.parse(actions)[0].link}>
                <Card variant="outlined">
                    <CardContent>
                        <Box display="flex" alignItems="center">
                            <Box pr={2}>
                                <Avatar src={iconUrl} alt="notification icon" />
                            </Box>
                            <Box>
                                <Typography>
                                    <strong>{title}</strong>
                                </Typography>
                                <Typography paragraph>{content}</Typography>
                                <Typography variant="caption">{new Date(timestamp).toLocaleString("en-IN")}</Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Link>
            {isRead === 0 ? (
                <Box top="0" right="16px" position="absolute">
                    <FiberNewOutlined fontSize="large" color="primary" />
                </Box>
            ) : null}
        </Box>
    );
}
export default NotificationCard;
