import departmentActionsTypes from "../../types/department.types";

export const allDepartments = (
    state: IAllDepartmentArray[] = [],
    action,
): IAllDepartmentArray[] => {
    if (action.type === departmentActionsTypes.FETCH_ALL_DEPARTMENTS) {
        return action.payload;
    } else if (action.type === departmentActionsTypes.CREATE_NEW_DEPARTMENT) {
        const newState = [...state];
        newState.push({ id: action.payload, name: action.data.name });
        return newState;
    } else {
        return state;
    }
};

export interface IAllDepartmentArray {
    id: number;
    name: string;
}
