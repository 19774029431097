import { IAssignedFeatureForTraining } from "../../../pages/feature_training/feature_training.types";
import featureTrainingActionsTypes from "../../types/feature_training.types";

/**
 * @param state
 * @param action
 */
export const assignedFeaturesForTraining = (
    state: IAssignedFeatureForTraining = {},
    action,
): IAssignedFeatureForTraining => {
    switch (action.type) {
        case featureTrainingActionsTypes.FETCH_TRAINING_FEATURE_LIST: {
            return action.payload;
        }
        case featureTrainingActionsTypes.FINISH_ASSIGNED_FEATURE_TRAINING: {
            const newState: IAssignedFeatureForTraining = { ...state };
            const selectedFeatures: string[] = action.payload;
            /**
             * update trained status of selected features
             */
            newState.features = state.features!.map((feature) => {
                if (selectedFeatures.includes(feature.featureId.toString())) {
                    feature.trained = 1;
                    feature.completedById = localStorage.getItem("empID");
                    feature.timestamp = Date.now();
                }
                return feature;
            });
            return newState;
        }
        default:
            return state;
    }
};

export const features = (state = [], action) => {
    switch (action.type) {
        case featureTrainingActionsTypes.FETCH_FEATURES: {
            return action.payload;
        }
        default:
            return state;
    }
};
