import { List, Assignment, LocalActivity, Block } from "@mui/icons-material";

/**
 * @author Gulam Hussain, 24 January 2020
 *
 *
 *
 * For material UI icons
 * https://material-ui.com/components/material-icons/
 */

const featureList: IFeatureList = {
    Candidate: {
        "view-candidate-list": {
            url: "/candidates",
            name: "Candidates List",
            icon: List,
            isProtected: true,
            isSidebar: true,
        },
        "candidate-template": {
            url: "/template",
            name: "Template",
            icon: Assignment,
            isProtected: true,
            isSidebar: true,
        },
        "admin-activity": {
            url: "/admin-activity",
            name: "Admin Activity",
            icon: LocalActivity,
            isProtected: true,
            isSidebar: true,
        },
        "black-listed": {
            url: "/black-listed",
            name: "Black Listed ",
            icon: Block,
            isProtected: true,
            isSidebar: true,
        },
    },
    "Feature Training": {
        "feature-training": {
            url: "/feature-training",
            name: "Feature Training",
            icon: List,
            isProtected: false,
            isSidebar: false,
        },
    },
    Tasks: {
        "onboarding-tasks": {
            url: "/onboarding/tasks",
            name: "Onboarding Tasks",

            icon: LocalActivity,
            isProtected: false,
            isSidebar: true,
        },
    },
};

export default featureList;

export interface IFeatureList {
    [key: string]: ISubFeature;
}

export interface ISubFeature {
    [key: string]: ISubFeatureText;
}

export interface ISubFeatureText {
    url: string;
    name: string;
    icon: any;
    isProtected: boolean;
    isSidebar: boolean;
}
