import {
    ICandidateEventObject,
    ICandidateFullInfo,
    ICandidateRowData,
} from "../../../pages/candidates/components/detailed_panel/detailed_panel.type";
import candidateActionTypes, {
    ICandidateEventsAfterCompletionReduxState,
    ICandidateEventsBeforeCompletionReduxState,
    ICandidatesTimeLineInfoReduxState,
} from "../../types/candidate.types";

/**
 * @author Gulam Hussain
 *
 * This is candidate List state object, an array of candidates
 */
export const candidatesList = (state: ICandidateRowData[] = [], action) => {
    switch (action.type) {
        case candidateActionTypes.FETCH_ALL_CANDIDATES_LIST: {
            if (action.loadMore) {
                // in case of load more event, prepend newly fetch data to existing state array
                return [...state, ...action.payload];
            }
            return action.payload;
        }
        case candidateActionTypes.CREATE_NEW_CANDIDATE_PROFILE: {
            // add a new candidate profile data to local state
            return [action.payload, ...state];
        }
        case candidateActionTypes.REJECT_CANDIDATE_APPLICATION: {
            // remove rejected candidate from local state
            const newState = [...state];
            // find candidate object using candidateId
            const candidateIndex = newState.findIndex((candidate) => {
                return candidate.candidateId === action.candidateId;
            });
            newState.splice(candidateIndex, 1);
            return newState;
        }
        case candidateActionTypes.UPDATE_COMPLETED_EVENT_COUNT: {
            const newState = [...state];
            // find candidate object using candidateId
            const candidateObj = newState.find((candidate) => {
                return candidate.candidateId === action.candidateId;
            });
            if (candidateObj) {
                candidateObj.completedEvents = candidateObj.completedEvents + 1;
            }
            return newState;
        }
        case candidateActionTypes.UPDATE_BASE_LOCATION_IN_CANDIDATE_LIST: {
            const newState = [...state];
            // find candidate object using candidateId
            const candidateObj = newState.find((candidate) => {
                return candidate.candidateId === action.payload.candidateId;
            });
            if (candidateObj) {
                // update base location, base location lat, base location lan
                candidateObj.baseLocation = action.payload.baseLocation;
                candidateObj.baseLocationLat = action.payload.baseLocationLat;
                candidateObj.baseLocationLon = action.payload.baseLocationLon;
            }
            return newState;
        }
        case candidateActionTypes.UPDATE_CANDIDATE_ROW_INFO: {
            const newState = [...state];
            // find candidate object using candidateId
            const candidateIndex = newState.findIndex((candidate) => {
                return candidate.candidateId === action.candidateId;
            });
            newState[candidateIndex] = Object.assign({}, newState[candidateIndex], action.payload);
            return newState;
        }
        case candidateActionTypes.UPDATE_CANDIDATE_DOJ_IN_ROW_INFO: {
            const newState = [...state];
            // find candidate object using candidateId
            const candidateIndex = newState.findIndex((candidate) => {
                return candidate.candidateId === action.candidateId;
            });
            newState[candidateIndex].joiningDate = action.payload.doj;
            return newState;
        }
        case candidateActionTypes.ADD_CANDIDATE_TO_THE_BLACK_LIST: {
            const newState = [...state];
            // find candidate object using candidateId
            const candidateObj = newState.find((candidate) => {
                return candidate.candidateId === action.payload.candidateId;
            });
            if (candidateObj) {
                candidateObj.isBlackListed = 1;
            }
            return newState;
        }
        default:
            return state;
    }
};

/**
 * candidatesTimelineInfo is object which maps candidateId to its detailed info
 */
export const candidatesTimelineInfo = (state: ICandidatesTimeLineInfoReduxState = {}, action) => {
    switch (action.type) {
        case candidateActionTypes.FETCH_CANDIDATE_TIMELINE_INFO: {
            const payload: ICandidateFullInfo = action.payload;
            // calculate total events and completed events of the candidate
            payload.totalEvents = payload.events.length;
            payload.completedEvents = payload.events.filter((event) => event.isCompleted).length;
            const newState = { ...state };
            // add a new key value pair, key being candidateId and value is a big object containing detailed data
            newState[action.candidateId] = payload;
            return newState;
        }
        case candidateActionTypes.FINISH_CANDIDATE_EVENT: {
            // whenever any event is finished/completed we need to update its isComplete value from 0 to 1
            const newState = { ...state };
            const candidateId = action.candidateId;
            // get the candidateEvents by using candidateId
            const candidateEvents = { ...newState[candidateId] }.events;
            // find the event by eventId
            const findEvent = candidateEvents.find((event) => {
                return event.eventId === action.eventId;
            });
            // found event, update its state
            if (findEvent) {
                findEvent.isCompleted = true;
                findEvent.completedAt = Date.now();
                findEvent.completedBy = localStorage.getItem("fullName");
            }
            return newState;
        }
        case candidateActionTypes.RESEND_CANDIDATE_EVENT_EMAIL: {
            // resend and email for event
            const newState = { ...state };
            const candidateId = action.candidateId;
            const candidateEvents = { ...newState[candidateId] }.events;
            const candidateEvent: ICandidateEventObject | undefined = candidateEvents.find((event) => {
                return event.eventId === action.eventId;
            });
            if (candidateEvent) {
                // find email report
                const emailReport = candidateEvent.emailDeliveryReport.find(
                    (report) => report.emailDeliveryId === action.emailDeliveryId
                );
                emailReport!.emailDeliveryStatus = "sent";
            }
            return newState;
        }
        case candidateActionTypes.UPLOAD_EXTRA_DOCUMENTS: {
            /**
             * update candidate document array and add a extra document when admin upload the document
             */
            const newState = { ...state };
            const candidateInfo = Object.assign({}, newState[action.payload.candidateId]);
            // get the candidate documents
            const documents = [...candidateInfo.documents];
            // add newly added document
            documents.push(action.payload);
            candidateInfo.documents = documents;
            newState[action.payload.candidateId] = candidateInfo;
            return newState;
        }
        case candidateActionTypes.UPDATE_BASE_LOCATION_IN_CANDIDATE_DETAIL_INFO: {
            /**
             * update base location info in detailed info
             */
            const newState = { ...state };
            const candidateInfo = Object.assign({}, newState[action.payload.candidateId]);
            // get the candidate documents
            // update base location, base location lat, base location lan
            candidateInfo.baseLocation = action.payload.baseLocation;
            candidateInfo.baseLocationLat = action.payload.baseLocationLat;
            candidateInfo.baseLocationLon = action.payload.baseLocationLon;
            newState[action.payload.candidateId] = candidateInfo;
            return newState;
        }
        case candidateActionTypes.CANDIDATE_LAST_KNOWN_LOCATION: {
            const newState = { ...state };
            const candidateObj = newState[action.candidateId];
            if (candidateObj) {
                candidateObj.lastLocationData = action.payload;
            }
            return newState;
        }
        case candidateActionTypes.UPDATE_HANDLER: {
            // update candidate handler for particular department
            const newState = { ...state };
            const candidateInfo = newState[action.payload.candidateId];
            const handlerToBeUpdated = candidateInfo.handlers.find(
                (handlerObj) => handlerObj.handlerDepartmentId === action.payload.handler.departmentId
            );
            if (handlerToBeUpdated) {
                handlerToBeUpdated.handlerEmpId = action.payload.handler.empId;
                handlerToBeUpdated.empName = action.payload.selectedEmpName;
            }
            return newState;
        }
        case candidateActionTypes.UPDATE_CANDIDATE_DETAILED_INFO: {
            const newState = { ...state };
            const candidateId = action.candidateId;
            newState[candidateId] = Object.assign({}, newState[candidateId], action.payload);
            return newState;
        }
        case candidateActionTypes.UPDATE_CANDIDATE_DOJ_IN_DETAILED_INFO: {
            const newState = { ...state };
            const candidateId = action.candidateId;
            newState[candidateId].joiningDate = action.payload.doj;
            return newState;
        }
        case candidateActionTypes.GET_CANDIDATE_BASE_LOCATION_HISTORY: {
            const newState = { ...state };
            const payload: any = action.payload;
            const candidate: ICandidateFullInfo = Object.assign({}, newState[action.candidateId]);
            candidate.baseLocationHistory = payload;
            newState[action.candidateId] = candidate;
            return newState;
        }
        default:
            return state;
    }
};

/**
 * fetch candidate event data which might be required to complete event data, not all
 * event will require this
 * @param state
 * @param action
 */
export const candidateEventsDataBeforeCompletion = (state: ICandidateEventsBeforeCompletionReduxState = {}, action) => {
    switch (action.type) {
        case candidateActionTypes.FETCH_CANDIDATE_EVENT_DATA_BEFORE_COMPLETION: {
            const { candidateId, eventName } = action;
            const newState = { ...state };
            if (newState[candidateId]) {
                newState[candidateId][eventName] = action.payload;
            } else {
                newState[candidateId] = {};
                newState[candidateId][eventName] = action.payload;
            }
            return newState;
        }
        case candidateActionTypes.UPDATE_OPS_FEATURE_TRAINING_STATUS: {
            /**
             * CONTEXT: In ops-training event, user can select multiple features for training and  submit
             * This case updates selected features trained variable from 0 to 1
             * action.payload.features is an array of selected features ID
             */
            const newState = { ...state };
            // get the event
            const opsEvent = newState[action.payload.candidateId][action.payload.eventName];
            opsEvent.eventInfo!.features = opsEvent.eventInfo!.features!.map((feature) => {
                // update feature trained status to 1 if payload features array includes feature id
                if (action.payload.features.includes(feature.featureId.toString())) {
                    // update trained status to 1
                    return Object.assign({}, feature, { trained: 1, timestamp: Date.now() });
                }
                return feature;
            });
            return newState;
        }
        case candidateActionTypes.UPDATE_OPS_FEATURE_ASSIGNMENT_STATUS: {
            /**
             * This case update feature assignment status.
             * CONTEXT: In ops-training event, user can assign a feature to some other employee
             * so when feature assignment happens, update the assigned feature status from assigned 0 to 1
             */
            const newState = { ...state };
            if (!newState[action.payload.candidateId]) {
                return newState;
            }
            const opsEvent = newState[action.payload.candidateId][action.payload.eventName];

            const assignedFeature = opsEvent.eventInfo!.features!.find(
                (feature) => feature.featureId === action.payload.featureId
            );
            if (assignedFeature) {
                // update the assigned, and other basic details of assigned feature
                assignedFeature.assigned = 1;
                assignedFeature.assignedOn = Date.now();
                assignedFeature.assignedTo = "NA";
                assignedFeature.assignedToId = action.payload.assignTo;
            }
            return newState;
        }
        default:
            return state;
    }
};

/**
 * fetch candidate event data after event completion
 * event will require this
 * @param state
 * @param action
 */
export const candidateEventsDataAfterCompletion = (state: ICandidateEventsAfterCompletionReduxState = {}, action) => {
    switch (action.type) {
        case candidateActionTypes.FETCH_CANDIDATE_EVENT_DATA_AFTER_COMPLETION: {
            const { candidateId, eventName } = action;
            const newState = { ...state };
            if (newState[candidateId]) {
                newState[candidateId][eventName] = action.payload;
            } else {
                newState[candidateId] = {};
                newState[candidateId][eventName] = action.payload;
            }
            return newState;
        }
        case candidateActionTypes.UPDATE_BASE_LOCATION_IN_EVENT: {
            /**
             * Update base location in "specify-base-location: event.
             */
            const newState = { ...state };
            const { candidateId } = action.payload;
            // find the baseLocationEvent data
            const baseLocationEvent = newState[candidateId]["specify-base-location"];
            if (baseLocationEvent) {
                //  action.payload is an object containing baseLocationLat, baseLocationLon etc.
                baseLocationEvent.data = action.payload;
            }
            return state;
        }
        default:
            return state;
    }
};

export const externalUserLink = (state = [], action) => {
    switch (action.type) {
        case candidateActionTypes.GET_EXTERNAL_USER_LINK:
            return action.payload;
        default:
            return state;
    }
};
