import store from "../store/store";
import notificationTypes from "../store/types/notification.types";

export function hasError(error) {
    if (!error.response) { return true; }
    return error.response.data.errors.length > 0;
}

export function createNotifications(notifications, variant) {
    const notification = notifications.map((error) => {
        const key = new Date().getTime() + Math.random();
        return {
            message: error.msg || "An error occurred, Please try again",
            options: {
                variant,
                key,
                preventDuplicate: false,
                autoHideDuration: 4000,
            },
        };
    });
    store.dispatch({ type: notificationTypes.ENQUEUE_SNACKBAR, payload: notification });
}
