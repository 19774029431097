import React from "react";
import { Box, Typography, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import SVG from "./no-data.svg";
function NoDataUI(props: INoDataFoundProps) {
    if (!props.show) {
        return null;
    }
    return (
        <Box my={2} textAlign="center">
            <Box px={5} py={3} borderRadius="5px" display="inline-block">
                <img alt="no data" style={{ maxWidth: "300px", marginBottom: "25px" }} src={SVG} />
                <Typography variant="h5" gutterBottom>
                    {props.message || "No Data is available"}
                </Typography>
            </Box>
        </Box>
    );
}
export default NoDataUI;
interface INoDataFoundProps {
    message?: string;
    show: boolean;
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}
