import documentActionTypes from "../../types/documents.types";

export const allDocumentTypes = (state: IAllDocumentTypes[] = [], action): IAllDocumentTypes[] => {
    if (action.type === documentActionTypes.FETCH_ALL_DOCUMENTS) {
        return action.payload;
    } else {
        return state;
    }
};
export interface IAllDocumentTypes {
    id: number;
    type: string;
}
