import React, { Component, ReactNode } from "react";

/**
 * @author Gulam Hussain 09 March 2020
 * @description Reusable component to load more data on page scroll
 */
class ScrollLoader extends Component<Props, any> {
    container: any;
    prevRatio = 0;
    constructor(props) {
        super(props);
        this.container = React.createRef();
    }

    componentDidMount() {
        this.createObserver();
    }

    createObserver = () => {
        let observer;
        const options = {
            root: this.props.containerEl || null, // null means document viewport
            rootMargin: "0px",
            threshold: 1,
        };
        observer = new IntersectionObserver(this.handleIntersect, options);
        observer.observe(this.container.current);
    };

    handleIntersect = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > this.prevRatio && !this.props.isLoading && this.props.hasMoreData) {
                this.props.onLoad();
            }
            this.prevRatio = entry.intersectionRatio;
        });
    };
    render() {
        return <div ref={this.container}></div>;
    }
}

export default ScrollLoader;

interface Props {
    isLoading: boolean;
    onLoad(): any;
    hasMoreData: boolean;
    message?: string;
    containerEl?: HTMLElement;
    loader?: ReactNode | HTMLElement;
}
