export interface ICreateNewCandidateEvent {
    description: string;
    eventName: string;
    handlerDepartmentId?: string,
    isOptional: string;
    linkedToEvent?: string;
    shouldFetch: string;
    title: string;
};

export interface IFetchCandidateEvent extends ICreateNewCandidateEvent {
    id: number;
    eventId: number;
}

export interface ICreateDepartmentFeatureMapping {
    departmentId: number|string;
    featureId?: number;
    isActive: number|string;
    featureName: string;
}

export interface IFetchDepartmentFeatureMapping extends ICreateDepartmentFeatureMapping {
    id: number;
    mappingId: number;
    departmentName: string;
}

export interface ICreateCandidateEventsDepartmentMapping {
    eventId: number|string;
    departmentId: number|string;
    sequenceId: number|string;
    handlerDepartmentId?: number;
    employeeTypeId: number|string;
}

export interface IFetchCandidateEventDepartmentMapping extends ICreateCandidateEventsDepartmentMapping {
    id: number;
    mappingId: number;
    eventName: string;
    departmentName: string;
    employeeTypeName: string;
    // handlerDepartmentName?: string;
}

export interface ICreateHandlerMaster {
    departmentId: string|number;
    handlerEmpId: string;
    handlerDepartmentId: string|number;
    isActive: string|number;
    handlerEmployee?: string;
}

export interface IFetchHandlerMaster extends ICreateHandlerMaster {
    id: number;
    department: string;
    handlerDepartment: string;
    handlerEmployee: string;
}

interface IAdminActivityTypes {
    FETCH_CANDIDATE_EVENT: string;
    CREATE_CANDIDATE_EVENT: string;
    FETCH_DEPARTMENT_FEATURE_MAPPING: string;
    CREATE_DEPARTMENT_FEATURE_MAPPING: string;
    FETCH_CANDIDATE_EVENTS_DEPARTMENT_MAPPING: string;
    CREATE_CANDIDATE_EVENTS_DEPARTMENT_MAPPING: string;
    FETCH_DEPARTMENT_WISE_HANDLERS: string;
    CREATE_DEPARTMENT_WISE_HANDLERS: string;
}

const adminActivityActionsTypes: IAdminActivityTypes = {
    FETCH_CANDIDATE_EVENT: "FETCH_CANDIDATE_EVENT",
    CREATE_CANDIDATE_EVENT: "CREATE_CANDIDATE_EVENT",
    FETCH_DEPARTMENT_FEATURE_MAPPING: "FETCH_DEPARTMENT_FEATURE_MAPPING",
    CREATE_DEPARTMENT_FEATURE_MAPPING: "CREATE_DEPARTMENT_FEATURE_MAPPING",
    FETCH_CANDIDATE_EVENTS_DEPARTMENT_MAPPING: "FETCH_CANDIDATE_EVENTS_DEPARTMENT_MAPPING",
    CREATE_CANDIDATE_EVENTS_DEPARTMENT_MAPPING: "CREATE_CANDIDATE_EVENTS_DEPARTMENT_MAPPING",
    FETCH_DEPARTMENT_WISE_HANDLERS: "FETCH_DEPARTMENT_WISE_HANDLERS",
    CREATE_DEPARTMENT_WISE_HANDLERS: "CREATE_DEPARTMENT_WISE_HANDLERS"
};


export default adminActivityActionsTypes;
