interface ITemplateActionTypes {
    CREATE_CANDIDATE_TEMPLATE: string;
    FETCH_CANDIDATE_TEMPLATE: string;
}

const templateActionTypes: ITemplateActionTypes = {
    CREATE_CANDIDATE_TEMPLATE: "CREATE_CANDIDATE_TEMPLATE",
    FETCH_CANDIDATE_TEMPLATE: "FETCH_CANDIDATE_TEMPLATE"
};

export default templateActionTypes;
