import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

/**
 * @author Gulam Hussain, 08 March 2020
 * @param props { onClose(): void }
 * @description Browser notification sidebar header
 */
function NotificationSidebarHeader(props: { onClose(): void }) {
    return (
        <Box
            justifyContent="space-between"
            borderBottom="1px solid #ccc"
            display="flex"
            px={2}
            bgcolor="white"
            zIndex="1"
            py={1}
            position="sticky"
            top="0"
            alignItems="center">
            <Typography>Notifications</Typography>
            <IconButton onClick={props.onClose}>
                <Close />
            </IconButton>
        </Box>
    );
}

export default NotificationSidebarHeader;
