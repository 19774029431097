import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import user from "./login";
import { hasError, createNotifications } from "./notification_handler";
const isProd = process.env.NODE_ENV === "production";
const OLD_API: string | undefined = isProd ? process.env.REACT_APP_OLD_API_PROD : process.env.REACT_APP_OLD_API_LOCAL;
const NEW_API: string | undefined = isProd ? process.env.REACT_APP_NEW_API_PROD : process.env.REACT_APP_NEW_API_LOCAL;

type TSubscriberFunction = (val: string) => void;

// class Request
class Request {
    public client: AxiosInstance = axios.create();
    private baseUrl: string | undefined;
    private subscribers: TSubscriberFunction[] = [];
    private isRefreshing: boolean = false;
    constructor(baseURL: string | undefined) {
        if (baseURL !== undefined) {
            this.baseUrl = baseURL;
            this.subscribers = [];
            this.isRefreshing = false;
            this.beforeRequest = this.beforeRequest.bind(this);
            this.onRequestSuccess = this.onRequestSuccess.bind(this);
            this.onRequestFail = this.onRequestFail.bind(this);
            this.client = axios.create({
                baseURL: this.baseUrl,
                timeout: 360000,
            });
            this.client.interceptors.request.use(this.beforeRequest);
            this.client.interceptors.response.use(this.onRequestSuccess, this.onRequestFail);
        }
    }

    public beforeRequest(requestObj: AxiosRequestConfig) {
        const modifiedRequest = Object.assign({}, requestObj);
        modifiedRequest.headers.token = localStorage.getItem("accessToken");
        modifiedRequest.headers.empID = localStorage.getItem("empID");
        modifiedRequest.headers.appID = "12";
        modifiedRequest.headers.version = "1.1.0";
        return modifiedRequest;
    }

    public onRequestSuccess(config: any): any {
        return config.data;
    }

    public onRetrySuccess(accessToken: string) {
        this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
    }

    public addSubscriber(callback: (val: string) => void) {
        this.subscribers.push(callback);
    }

    public async onRequestFail(err: any) {
        const { response, config } = err;
        const originalReq = config;
        if (response && response.status === 401) {
            const retryOriginalRequest = new Promise((resolve) => {
                this.addSubscriber((accessToken: string) => {
                    originalReq.headers.token = accessToken;
                    resolve(request(originalReq));
                });
            });

            if (!this.isRefreshing) {
                this.isRefreshing = true;
                try {
                    const token: any = await user("", "").retryLogin();
                    this.isRefreshing = false;
                    this.onRetrySuccess(token);
                } catch (err) {
                    window.location.href = "/login";
                }
            }
            return retryOriginalRequest;
        } else if (response && response.status === 502) {
            localStorage.setItem("redirectUrl", window.location.href);
            window.location.href = "/maintainance";
        } else if (response && response.status === 403) {
            localStorage.clear();
            localStorage.setItem("redirectUrl", window.location.href);
            window.location.href = "/login";
        }
        if (hasError(err)) {
            if (!err.response) {
                const errors = [{ msg: "Unable to connect to our server, try again later" }];
                createNotifications(errors, "error");
            } else {
                createNotifications(err.response.data.errors, "error");
            }
        }
        throw response;
    }
}

export const request = new Request(OLD_API).client;

export const requestNew = new Request(NEW_API).client;
