const departmentActionsTypes: IDepartmentActionsTypes = {
    FETCH_ALL_DEPARTMENTS: "FETCH_ALL_DEPARTMENT",
    CREATE_NEW_DEPARTMENT: "CREATE_NEW_DEPARTMENT",
};

interface IDepartmentActionsTypes {
    FETCH_ALL_DEPARTMENTS: string;
    CREATE_NEW_DEPARTMENT: string;
}

export interface ICreateNewDepartment {
    name: string;
    appLink?: string;
}

export default departmentActionsTypes;
