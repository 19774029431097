import {IFeatureList, ISubFeature, ISubFeatureText} from "./feature_listing";
import getAllowedFeatures from "./get_allowed_features";

/**
 * This function removes the features that shhould not be listed in sidebar of the application
 *
 * It simply get the all allowed features for that particular employee, and filter out those object that should
 * not be shown in sidebar
 */
export default function getSidebarMenu(): IFeatureList {
    // get allowed and public (un protected) features
    const allowedFeatures = getAllowedFeatures();
    // object that will store only sidebar features
    const onlySidebarFeatures: IFeatureList = {};
    Object.keys(allowedFeatures).forEach(
        (mainFeatureText: string, index: number) => {
            const subFeaturesObject: ISubFeature = allowedFeatures[mainFeatureText];
            Object.keys(subFeaturesObject).forEach((subFeatureText: string) => {
                const featureData: ISubFeatureText = subFeaturesObject[subFeatureText];
                if (featureData.isSidebar) {
                    if (!onlySidebarFeatures[mainFeatureText]) {
                        onlySidebarFeatures[mainFeatureText] = {};
                    }
                    onlySidebarFeatures[mainFeatureText][subFeatureText] =
                        subFeaturesObject[subFeatureText];
                }
            });
        },
    );
    return onlySidebarFeatures;
}
