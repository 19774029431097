import Typography from "@mui/material/Typography";
import React from "react";
import maintanceIllustration from "./../../static/images/maintance.svg";
import classes from "./maintance.module.scss";

function Maintance(): any {
    return (
        <div className={classes.maintance}>
            <div className={classes.maintance_container}>
                <img src={maintanceIllustration} alt="Maintance illustration" />
                <Typography variant="h3" align="center">
                    Server Maintance
                </Typography>
                <Typography variant="h5" align="center">
                    Server is down for a bit of maintance right now, will soon be up again in 5-10 minutes.
                </Typography>
            </div>
        </div>
    );
}

export default Maintance;
