import types from "../../types/browser_notifications.types";

export function list(state = [], action) {
    switch (action.type) {
        case types.GET_BROWSER_NOTIFICATIONS: {
            const { data, nextCursor } = action.payload;
            return nextCursor ? [...state, ...data] : [...data];
        }
        case types.ADD_NEW_BROWSER_NOTIFICATION: {
            return [action.data, ...state];
        }
        default:
            return state;
    }
}
