import types from "../../types/template.types";

function candidateTemplates(state: [] = [], action: any) {
    switch (action.type) {
        case types.CREATE_CANDIDATE_TEMPLATE: {
            const newState: any[] = [].concat(state);
            newState.push(action.payload);
            return newState;
        }
        case types.FETCH_CANDIDATE_TEMPLATE: {
            return action.payload || [];
        }
        default:
            return state;
    }
}

export default candidateTemplates;
