// library imports
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// style imports
import { LinearProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/lab";
import { ThemeProvider } from "@mui/material/styles";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/custom_route/custom_route";
// Components import static
import Header from "./components/header/header";
import NotFound from "./components/404_page/404_page";
import Login from "./pages/login/login";
import Maintance from "./pages/maintance/maintance";
import "./static/styles/index.scss";
import checkAuth from "./util/check_auth";
import theme from "./util/theme/theme";
import { SnackbarProvider } from "notistack";
import Notification from "./components/notification/notification";
// Components import dynamic
const Home = lazy(() => import("./pages/index/index"));
const Candidates = lazy(() => import("./pages/candidates/candidates"));
const FeatureTraining = lazy(() => import("./pages/feature_training/feature_training"));
const Templates = lazy(() => import("./pages/template/template"));
const AdminActivity = lazy(() => import("./pages/admin_activity/admin_activity"));
const OnboardingTasks = lazy(() => import("./pages/onboarding_tasks"));
const BlackListedCandidates = lazy(() => import("./pages/black_listed_candidates"));

const path = window.location.pathname;

const HeaderComponent =
    path === "/login" ? (
        <div
            style={{
                height: "95px",
                backgroundColor: "#f6f6f6",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
            }}></div>
    ) : (
        <Header />
    );
function App() {
    if (path !== "/login" && !checkAuth()) {
        localStorage.setItem("redirectUrl", window.location.href);
        window.location.pathname = "/login";
        return null;
    }
    return (
        <div>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider maxSnack={7}>
                        <Notification />
                        <BrowserRouter>
                            <Suspense fallback={<LinearProgress color="secondary" />}>
                                <div className="site-container">
                                    {HeaderComponent}
                                    <Switch>
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/" component={Home} />
                                        <ProtectedRoute exact path="/candidates" component={Candidates} />
                                        <ProtectedRoute exact path="/feature-training" component={FeatureTraining} />
                                        <ProtectedRoute exact path="/template" component={Templates} />
                                        <ProtectedRoute exact path="/admin-activity" component={AdminActivity} />
                                        <ProtectedRoute exact path="/onboarding/tasks" component={OnboardingTasks} />
                                        <ProtectedRoute exact path="/black-listed" component={BlackListedCandidates} />
                                        <Route exact path="/maintance" component={Maintance} />
                                        <Route exact path="*" component={NotFound} />
                                    </Switch>
                                </div>
                            </Suspense>
                        </BrowserRouter>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
