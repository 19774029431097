import React, { useState } from "react";
import { ClickAwayListener, IconButton, Tooltip, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ExitToApp, AccountCircle } from "@mui/icons-material";

const fullName: any = localStorage.getItem("fullName");

function UserProfile() {
    const [isOpen, toggleOpen] = useState(false);
    const handleTooltipClose = () => {
        toggleOpen(false);
    };

    const handleTooltipOpen = () => {
        toggleOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={() => toggleOpen(false)}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={isOpen}
                arrow
                placement="bottom-end"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<UserProfileToolTip />}>
                <IconButton onClick={handleTooltipOpen}>
                    <AccountCircle style={{ color: "white" }} />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    );
}

export default UserProfile;

function UserProfileToolTip() {
    return (
        <List component="nav" aria-label="User profile option">
            <ListItem>
                <ListItemIcon>
                    <AccountCircle color="primary" />
                </ListItemIcon>
                <ListItemText primary={fullName || ""} />
            </ListItem>
            <ListItem onClick={logout} button>
                <ListItemIcon>
                    <ExitToApp color="primary" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>
        </List>
    );
}

async function logout() {
    localStorage.removeItem("fullName");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("aoc");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("featureArray");
    localStorage.removeItem("level");
    localStorage.removeItem("tokenExpiry");
    localStorage.removeItem("accountType");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("urlMapping");
    localStorage.removeItem("empID");
    localStorage.clear();
    try {
        await navigator.credentials.preventSilentAccess();
    } catch (err) {
        // no need to handle error
    }
    window.location.href = "/login";
}
