import types from "../../types/onboarding_tasks.types";
import { IOnboardingTask, IFinishEventPayload } from "../../../pages/onboarding_tasks/types";
export function list(state = [], action) {
    switch (action.type) {
        case types.OT_GET_ONBOARDING_EVENTS:
            return action.page === 1 ? [...action.payload] : [...state, ...action.payload];
        case types.OT_COMPLETE_EVENT: {
            const newState: IOnboardingTask[] = [...state];
            const { candidateId, eventId } = action.payload as IFinishEventPayload;
            const eventIndex = newState.findIndex(
                (event) => event.eventId === eventId && event.candidateId === candidateId
            );
            if (eventIndex > -1) {
                newState.splice(eventIndex, 1);
            }
            return newState;
        }
        default:
            return state;
    }
}
