import getAllowedFeaturesMapping from "./get_allowed_features";

/**
 * @author Gulam Hussain
 *
 * This function maps User feature names to page url and stores the final object
 * into localStorage to be used for feature access restrictions
 */
export default function urlToFeatureMapping() {
    try {
        const allowedFeatures: any = getAllowedFeaturesMapping();
        const urlMapping: any = {};
        Object.keys(allowedFeatures).forEach(
            (mainFeatureText: string, index: number) => {
                Object.keys(allowedFeatures[mainFeatureText]).forEach(
                    (subFeatureText: string) => {
                        const url: string =
                            allowedFeatures[mainFeatureText][subFeatureText]
                                .url;
                        urlMapping[url] = subFeatureText;
                    },
                );
            },
        );
        localStorage.setItem("urlMapping", JSON.stringify(urlMapping));
    } catch (err) {
        localStorage.setItem("urlMapping", "");
    }
}
