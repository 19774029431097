import React from "react";
import { Route } from "react-router-dom";
import NotFound from "../404_page/404_page";

export default function ProtectedRoute(props: any): any {
    let urlToFeatureMappingString: string;
    let urlToFeatureMappingObject: object;
    try {
        // get the stringyfied url to feature mapping data
        urlToFeatureMappingString = localStorage.getItem("urlMapping") || "";
        // convert it into object, (typescript is not allowing me to direct put stringyfied into JSON.parse, uhh)
        urlToFeatureMappingObject = JSON.parse(urlToFeatureMappingString) || {};
    } catch (err) {
        localStorage.clear();
        window.location.pathname = "/login";
        return null;
    }

    // get the path of current page
    const path: string = props.computedMatch.path;

    /**
     * if current url is present in @urlToFeatureMappingObject, it means user has access
     * to this page, let him/her access this page
     */
    const Component: any = urlToFeatureMappingObject[path] ? props.component : NotFound;
    return <Route {...props} component={Component} />;
}
