/**
 * This function is directly taken from Operation portal code
 * @description Method to stringify URL and dataObject for GET API use
 * @example getURL('/fetch/survey/images?',{dateFrom:12/05/2017,crop=potato}), URL may contain predefined parameters (someParam=someValue), question mark is optional.
 * @return returns a string like '/fetch/survey/images?dateFrom=12/05/2017&crop=potato'
 * @param url
 * @param dataObject
 */
import store from "../store/store";
import notificationTypes from "../store/types/notification.types";
export function getURL(url: string, dataObject: any): string {
    let str: string = "";
    for (const key in dataObject) {
        if (dataObject[key] !== null && dataObject[key] !== "") {
            str += key + "=" + escapeAmpersand(dataObject[key]) + "&";
        }
    }
    if (url.indexOf("?") === -1 && url.indexOf("=") === -1) {
        str = url + "?" + str.substr(0, str.length - 1);
    } else if (url.indexOf("?") > -1 && url.indexOf("=") === -1) {
        str = url + str.substr(0, str.length - 1);
    } else if (url.indexOf("?") === -1 && url.indexOf("=") > -1) {
        str = url + "?&" + str.substr(0, str.length - 1);
    } else if (url.indexOf("?") > -1 && url.indexOf("=") > -1) {
        str = url + "&" + str.substr(0, str.length - 1);
    } else if (url.indexOf("?") > -1 && url.indexOf("=") > -1 && url.indexOf("&") > -1) {
        str = url + str.substr(0, str.length - 1);
    }
    return str;
}

export function escapeAmpersand(value): any {
    return typeof value === "string" ? value.replace(/&/g, "%26") : value;
}

export function getQueryString(key: string) {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                "^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$",
                "i"
            ),
            "$1"
        )
    );
}

/**
 *
 * @param value any
 * @description Returns true if value is empty string
 */
export function isEmpty(value: any): boolean {
    return value === "";
}

/**
 *
 * @param value any
 * @description Returns true if value is numeric
 */
export function isNumeric(value: any): boolean {
    return !isNaN(value);
}

/**
 *
 * @param email string
 * @description Returns try if provided email is valid
 */
export function isEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

/**
 * @author Gulam Hussain, 01 March 2021
 * @param notification {INotification}
 * @description show Notification
 */
export const notify = (notification: INotification) => {
    const { message, variant } = notification;
    const key = new Date().getTime() + Math.random();
    const notifications = [
        {
            message,
            options: {
                variant: variant || "default",
                preventDuplicate: false,
                autoHideDuration: 4000,
                key,
                anchorOrigin: getAnchor(notification.anchorOrigin),
            },
        },
    ];
    store.dispatch({ type: notificationTypes.ENQUEUE_SNACKBAR, payload: notifications });
    return key;
};

function getAnchor(anchor: IAnchor | undefined) {
    if (anchor) {
        return anchor;
    }
    return {
        vertical: "top",
        horizontal: "left",
    };
}

interface INotification {
    message: string;
    variant?: "default" | "error" | "success" | "warning" | "info";
    anchorOrigin?: IAnchor;
}
interface IAnchor {
    vertical: "bottom" | "top";
    horizontal: "left" | "right";
}

/**
 * @author Gulam Hussain, 08 March 2020
 * Get permission to show browser notification.
 * @return Promise<"denied"|"granted">
 */
export async function getNotificationPermission(): Promise<"denied" | "granted"> {
    return new Promise((resolve, reject) => {
        if (!("Notification" in window)) {
            reject("reject");
        }

        // Let's check whether notification permissions have alredy been granted
        if (Notification.permission === "granted") {
            resolve("granted");
        }
        if (Notification.permission !== "denied") {
            try {
                Notification.requestPermission()
                    .then((permission) => {
                        if (permission === "granted") {
                            resolve(permission);
                        }
                        reject("denied");
                    })
                    .catch((err) => reject("denied"));
            } catch (error) {
                // Safari doesn't return a promise for requestPermissions and it
                // throws a TypeError. It takes a callback as the first argument
                // instead.
                if (error instanceof TypeError) {
                    Notification.requestPermission(function(permission) {
                        if (permission === "granted") {
                            resolve(permission);
                        }
                        reject(permission);
                    });
                } else {
                    reject("denied");
                    throw error;
                }
            }
        }
        reject("denied");
    });
}

export function getQueryParams() {
    const queryString = window.location.search;
    return queryString
        .slice(1)
        .split("&")
        .reduce((prev, curr) => {
            const [key, value] = curr.split("=");
            prev[key] = value;
            return prev;
        }, {});
}
