import actionTypes from "../../types/black_list_candidate";
import { IBlackListCandidate } from "../../../pages/black_listed_candidates/type";
export const blackListedCandidates = (state: IBlackListCandidate[] = [], action) => {
    switch (action.type) {
        case actionTypes.GET_BLACK_LIST_CANDIDATES: {
            if (action.nextCursor) {
                return [...state, ...action.payload];
            }
            return action.payload;
        }

        case actionTypes.ADD_CANDIDATE_TO_BLACK_LIST: {
            return [action.payload, ...state];
        }

        case actionTypes.REMOVE_CANDIDATE_FROM_BLACK_LIST: {
            const { id } = action.payload;
            return state.filter((candidate: IBlackListCandidate) => candidate.id !== parseInt(id, 10));
        }

        default:
            return state;
    }
};
