import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import classes from "./inline_block.module.scss";

/**
 * @author Gulam Hussain
 * @param props
 * @constructor
 * This is Common inline block, which renders the a message and icon if show property is true,
 * very handy to conditional render dumb logic like loading message or an error message etc.
 * I Would suggest you to use ../error_block component for error messages, it has retry callback feature.
 */
export default function Loader(props: IInlineLoader) {
    if (!props.show) {
        return null;
    }
    return (
        <div className={classes.inlineloader}>
            <Typography variant="h6" align="center">
                {props.message}
            </Typography>
            <div className={classes.icon_container}>{props.icon || <CircularProgress size={25} thickness={8} />}</div>
        </div>
    );
}

interface IInlineLoader {
    message: string;
    icon?: JSX.Element;
    show: boolean;
}
