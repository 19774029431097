import types from "../../types/notification.types";
const { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } = types;

export default (state: any = [], action) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            const notifications = Array.isArray(action.payload) ? action.payload : [action.payload];
            return [...state, ...notifications];

        case CLOSE_SNACKBAR:
            return [
                ...state.map((notification: any) =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ),
            ];

        case REMOVE_SNACKBAR:
            return [...state.filter((notification: any) => notification.options.key !== action.key)];

        default:
            return state;
    }
};
