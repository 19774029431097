const aocActionTypes: IAocActionTypes = {
    FETCH_ALL_AOC: "FETCH_ALL_AOC",
};

interface IAocActionTypes {
    FETCH_ALL_AOC: string;
}
export default aocActionTypes;

export interface IAocArrayTypes {
    costId: boolean;
    id: string;
    name: string;
    otherCostPerKg: number;
}
