const featureTrainingActionsTypes: IFeatureTrainingActionsTypes = {
    FETCH_TRAINING_FEATURE_LIST: "FETCH_TRAINING_FEATURE_LIST",
    FINISH_ASSIGNED_FEATURE_TRAINING: "FINISH_ASSIGNED_FEATURE_TRAINING",
    FETCH_FEATURES: "FETCH_FEATURES"
};

interface IFeatureTrainingActionsTypes {
    FETCH_TRAINING_FEATURE_LIST: string;
    FINISH_ASSIGNED_FEATURE_TRAINING: string;
    FETCH_FEATURES: string;
}

export default featureTrainingActionsTypes;
