import types from "../../types/onboarding_tasks.types";

export function eventsBeforeComplete(state = {}, action) {
    switch (action.type) {
        case types.OT_GET_ONBOARDING_EVENT_DATA_BEFORE_COMPLETE: {
            const newState = { ...state };
            const { data, candidateId, eventName } = action.payload;
            newState[`${candidateId}-${eventName}`] = data;
            return newState;
        }
        case types.OT_UPDATE_OPS_TRAINING_FEATURE_STATUS: {
            const newState = { ...state };
            const { candidateId, eventName, features } = action.payload;
            // get the event
            const event = newState[`${candidateId}-${eventName}`];
            event.eventInfo!.features = event.eventInfo!.features!.map((feature) => {
                // update feature trained status to 1 if payload features array includes feature id
                if (features.includes(feature.featureId.toString())) {
                    // update trained status to 1
                    return Object.assign({}, feature, { trained: 1, timestamp: Date.now() });
                }
                return feature;
            });
            return newState;
        }
        case "UPDATE_OPS_FEATURE_ASSIGNMENT_STATUS": {
            const newState = { ...state };
            const { candidateId, eventName } = action.payload;
            const event = newState[`${candidateId}-${eventName}`];
            if (!event) {
                return state;
            }
            const assignedFeature = event.eventInfo.features!.find(
                (feature) => feature.featureId === action.payload.featureId
            );
            if (assignedFeature) {
                // update the assigned, and other basic details of assigned feature
                assignedFeature.assigned = 1;
                assignedFeature.assignedOn = Date.now();
                assignedFeature.assignedTo = "NA";
                assignedFeature.assignedToId = action.payload.assignTo;
            }
            return newState;
        }
        default:
            return state;
    }
}

export function eventsAfterComplete(state = {}, action) {
    switch (action.type) {
        case types.OT_GET_ONBOARDING_EVENT_DATA_AFTER_COMPLETE: {
            const newState = { ...state };
            const { data, candidateId, eventName } = action.payload;
            newState[`${candidateId}-${eventName}`] = data;
            return newState;
        }
        default:
            return state;
    }
}
