import employeeActionTypes from "../../types/employee.types";

const defaultState = [];

export const users = (state: any = defaultState, action) => {
    if (action.type === employeeActionTypes.FETCH_EMPS_BY_NAME) {
        return action.payload;
    } else {
        return state;
    }
};

export function employeeTypes(state: any = [], action) {
    switch (action.type) {
        case employeeActionTypes.FETCH_EMPLOYEE_TYPES:
            return action.payload;
        case employeeActionTypes.CREATE_NEW_EMPLOYEE_TYPE: {
            const newState = [...state];
            newState.push({
                id: action.payload.employeeTypeId,
                name: action.data.name,
            });
            return newState;
        }
        default:
            return state;
    }
}
