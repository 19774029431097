import allFeatures, {IFeatureList, ISubFeature} from "./feature_listing";

/**
 * @author Gulam Hussain, 25 January 2020
 *
 * Filter allowed features from feature list
 */

// export default featureList;

export default function getAllowedFeatures(): IFeatureList {
    let userAllowedFeatures: [];
    try {
        const features: any = localStorage.getItem("featureArray");
        userAllowedFeatures = JSON.parse(features) || [];
    } catch (err) {
        userAllowedFeatures = [];
    }
    const userAllowedFeaturesHash: {[key: string]: boolean} = {};
    /**
     * create a simple object, to make feature access check easy and efficient
     */
    userAllowedFeatures.forEach((feature: string) => {
        userAllowedFeaturesHash[feature] = true;
    });

    const finalFeatures: IFeatureList = {};

    /**
     * Create a final Hash, which will include all public routes and routes
     *  that user has acccess to.
     */
    Object.keys(allFeatures).forEach((mainFeatureText: string) => {
        /**
         * Loop over Main Menus list.
         * If user doesn't have access to any of sub menus and none of
         * the sub menu is public, then that Main Menu will not be in final List.
         */
        const subFeaturesObject: ISubFeature = allFeatures[mainFeatureText];
        Object.keys(subFeaturesObject).forEach((subFeature) => {
            /**
             * check if sub menu is either public OR user has access to it
             */
            if (
                userAllowedFeaturesHash[subFeature] ||
                !subFeaturesObject[subFeature].isProtected
            ) {
                /**
                 * if final feature Hash doesn't have current iterating Menu
                 * then create it
                 */
                if (!finalFeatures[mainFeatureText]) {
                    finalFeatures[mainFeatureText] = {};
                }
                // add submenu
                finalFeatures[mainFeatureText][subFeature] =
                    subFeaturesObject[subFeature];
            }
        });
    });
    return finalFeatures;
}
