import { Box, Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { Component } from "react";
import ErrorBlock from "../../components/error_block/error_block";
import urlToFeatureMapping from "../../util/features/url_feature_mapping";
import User from "../../util/login";
import * as Util from "../../util/util";
import Logo from "./../../static/images/mgLogo.png";
import classes from "./login.module.scss";
import { ILogin, ILoginState } from "./login.types";

/**
 * @author Gulam Hussain
 * Login page
 */
class Login extends Component<any, ILoginState> implements ILogin {
    public passwordInput;
    constructor(props: object) {
        super(props);
        this.state = {
            formData: {
                userName: "",
                password: "",
            },
            showPasswordText: false,
            validationError: "",
            responseError: "",
            serverError: "",
            submitting: false,
        };
        this.passwordInput = React.createRef();
    }

    public async componentDidMount() {
        // try to get credentials from either browser (using credentials api)  or from url
        const credentials: any = await this.getStoredCredentials();
        if (credentials && credentials.id && credentials.password) {
            // if valid credentials found then try to login
            this.setState(
                {
                    formData: {
                        userName: credentials.id,
                        password: credentials.password,
                    },
                },
                this.submitForm
            );
        }
    }

    public getStoredCredentials = async () => {
        try {
            // check if url has info string
            const info: string = Util.getQueryString("info");
            if (info) {
                return JSON.parse(atob(info));
            } else {
                // try to get the password from browser itself
                return await navigator.credentials.get({
                    password: true,
                    mediation: "required",
                });
            }
        } catch (err) {
            return null;
        }
    };
    public togglePasswordVisibility = () => {
        this.setState(
            (state) => ({
                showPasswordText: !state.showPasswordText,
            }),
            () => this.passwordInput.current.focus()
        );
    };
    public handleChange = (e: any) => {
        const { name, value } = e.target;
        const formData = this.state.formData;
        formData[name] = value;
        this.setState({ formData });
    };

    public validateForm = (e) => {
        e.preventDefault();
        const { userName, password } = this.state.formData;
        if (userName === "" || password === "") {
            this.setState({
                validationError: "Username and password is required",
                serverError: "",
                responseError: "",
            });
            return;
        }
        this.submitForm();
    };

    public submitForm = async () => {
        this.setState({
            validationError: "",
            submitting: true,
            serverError: "",
            responseError: "",
        });
        const { userName, password } = this.state.formData;
        try {
            await User(userName.trim(), password).login();
            this.setState({ submitting: false });
            await this.storeCredentials(userName, password, localStorage.getItem("fullName"));
            /**
             * Upon login, create a url:feature object, which will be used on
             * each protected route to check feature access
             */
            urlToFeatureMapping();
            const redirectUrl = localStorage.getItem("redirectUrl") || "/candidates";
            localStorage.removeItem("redirectUrl");
            window.location.href = redirectUrl;
        } catch (err) {
            // this.setState({ submitting: false, serverError: err });
            this.setState({ submitting: false });
        }
    };

    public storeCredentials = async (username, password, fullName) => {
        /**
         * if credentials api is supported then store credentials in browser it self,
         */
        try {
            const credentials = new PasswordCredential({
                id: username,
                name: fullName,
                password,
                iconURL: "http://onboarding.meragaonapp.com/static/media/mini-logo.e995bdb4.png",
            });
            await navigator.credentials.store(credentials);
        } catch (err) {
            // no need to handle error
        }
    };

    public render() {
        const { userName, password } = this.state.formData;
        const { submitting, serverError, validationError, responseError, showPasswordText } = this.state;
        return (
            <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
                <Box className={classes.login_wrapper}>
                    <Box py={4}>
                        <img height="60px" src={Logo} alt="" />
                        <Typography variant="h5">Mera Gaon</Typography>
                    </Box>
                    <Typography align="left" variant="subtitle1">
                        Please Login
                    </Typography>
                    <form onSubmit={this.validateForm} action="">
                        <Box my={3}>
                            <TextField
                                value={userName}
                                onChange={this.handleChange}
                                name="userName"
                                label="Username"
                                variant="outlined"
                                fullWidth
                                id="username"
                                aria-describedby="my-helper-text"
                            />
                        </Box>
                        <Box my={3}>
                            <TextField
                                name="password"
                                onChange={this.handleChange}
                                value={password}
                                label="Password"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ref: this.passwordInput,
                                }}
                                type={showPasswordText ? "text" : "password"}
                                id="password"
                                aria-describedby="my-helper-text"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={this.togglePasswordVisibility}>
                                            {showPasswordText ? (
                                                <Visibility color="disabled" />
                                            ) : (
                                                <VisibilityOff color="disabled" />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Box>
                        <ErrorBlock show={!!serverError} message={serverError} />
                        <ErrorBlock show={!!validationError} message="Username and password is required." />
                        <ErrorBlock show={!!responseError} message={responseError} />
                        <Box>
                            <Button
                                type="submit"
                                endIcon={submitting ? <CircularProgress thickness={8} size={26} /> : null}
                                disabled={submitting}
                                fullWidth
                                variant="contained"
                                color="primary">
                                Login
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        );
    }
}

export default Login;
