const employeeActionTypes: IEmployeeActionsTypes = {
    FETCH_EMPS_BY_NAME: "FETCH_EMPS_BY_NAME",
    FETCH_EMPLOYEE_TYPES: "FETCH_EMPLOYEE_TYPES",
    CREATE_NEW_EMPLOYEE_TYPE: "CREATE_NEW_EMPLOYEE_TYPE"
};

interface IEmployeeActionsTypes {
    FETCH_EMPS_BY_NAME: string;
    FETCH_EMPLOYEE_TYPES: string;
    CREATE_NEW_EMPLOYEE_TYPE: string;
}

export default employeeActionTypes;

export interface IEmpSearchByName {
    EmpId?: string;
    MobileNo?: string;
    Name?: string;
}

export interface ICreateEmployeeType {
    name: string;
}
